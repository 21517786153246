@import "../../assets/scss/abstracts/mixins";
@import "../../assets/scss/abstracts/variables";

.raffle-product-card {
  // max-width: 25vw;
  &__media {
    // img {
    //   height: auto;
    //   width: 100%;
    // }
    .product-carousel {
      max-height: unset;
      height: 400px;
      @include breakpoint($md) {
        min-height: unset;
        height: 300px;
      }
      &__carousel {
        width: 100%;
        &__button {
          height: 40px;
          width: 40px;
          &__icon {
            font-size: 1rem;
          }
          &--left {
            left: 0.5rem;
          }
          &--right {
            right: 0.5rem;
          }
        }
        &__nav {
          display: none;
        }
      }
    }
    .carousel-root {
      .carousel-slider {
        position: relative;
        .control-arrow {
          background-color: #fff;
          -webkit-box-shadow: 0 0 20px 0.5px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 20px 0.5px rgba(0, 0, 0, 0.1);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          opacity: 1;
          color: #000;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          &.control-next {
            right: 5px;
            &::before {
              border-left: 7px solid #000;
              border-bottom: 7px solid #fff;
            }
          }
          &.control-prev {
            left: 5px;
            &::before {
              border-right: 7px solid #000;
            }
          }
        }
      }
    }
    .thumbs-wrapper {
      ul.thumbs {
        li {
          width: auto !important;
          img {
            height: 40px;
            @include breakpoint($md) {
              height: 50px;
              width: auto;
            }
          }
        }
      }
    }
  }
  &__bids {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 0.6rem;
    flex-wrap: wrap;
    @include breakpoint($md) {
      flex-direction: column;
    }
    p {
      font-size: 0.85rem;
      @include breakpoint($md) {
        font-size: 0.9rem;
      }
      span {
        margin: 0;
        @include breakpoint($md) {
          font-size: 0.8rem;
        }
      }
    }
    &__container {
      max-height: 200px;
      overflow-y: scroll;
      padding-right: 0.2rem;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 11px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgb(0, 0, 0);
      }
      @include breakpoint($md) {
        max-height: 300px;
      }
    }
    &__input {
      p {
        font-weight: bolder;
        text-align: center;
        margin: 0.5rem 0;
        a {
          color: #0073c0;
        }
      }
      form {
        margin-top: 1rem;
        border-top: 1px solid #cacaca;
        padding-top: 0.5rem;
        .error-feedback {
          color: $red;
          font-size: 0.9rem;
          font-weight: bolder;
          margin-top: 0.5rem;
        }
        .form-control-plaintext {
          font-family: $secondary-font;
          display: block;
          @include breakpoint($xl) {
            font-size: 1rem;
          }
          // @include breakpoint($xl2) {
          //   text-align: right;
          //   display: inline-block;
          // }
        }
        .form-control {
          background-color: #fff;
          border: 1px solid #ced4da;
          color: #495057;
          display: block;
          font-family: $secondary-font;
          font-size: 1rem;
          font-weight: 400;
          height: 40px;
          line-height: 1.5;
          padding: 0.5rem 0.75rem;
          // transition: border-color 0.15s ease-in-out,
          //   box-shadow 0.15s ease-in-out;
          width: max-content;
          margin: 0;
          @include breakpoint($md) {
            font-size: 0.9rem;
          }
          &.number {
            display: inline-block;
            width: 80px;
            text-align: center;
            margin-top: 0;
          }
        }
        label {
          font-family: $secondary-font;
          font-weight: bolder;
          font-size: 0.7rem;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #848484;
          letter-spacing: 0.5px;
        }
        .bid-container {
          margin-top: 5px;
          display: flex;
        }
        button {
          background-color: #000;
          color: #fff;
          padding: 0 0.8rem;
          height: 40px;
          cursor: pointer;
          &:hover {
            // background-color: #495057;
          }
          &:disabled {
            background-color: #727272;
            cursor: not-allowed;
          }
          @include breakpoint($xl) {
            display: inline-block;
          }
          &.send {
            display: block;
            width: 100%;
            border: 0;
            background-color: #000;
            color: #fff;
            padding: 1rem 0;
            font-weight: bolder;
            font-family: $main-font;
            font-size: 1rem;
            height: auto;
            &:disabled {
              background-color: #828282 !important;
            }
          }
        }
      }
    }
    &__next-bid {
      display: block;
      margin: 5px 0 !important;
      font-size: 0.8rem !important;
      color: #0070ba !important;
    }
  }
  &__body {
    padding: 1rem 5px;
    h4 {
      font-size: 1rem;
      margin: 0.3rem 0;
    }
    a {
      color: #000;
      &:hover {
        color: $red;
      }
      h5 {
        font-size: 1.3rem;
      }
    }
    h6 {
      font-size: 1.5rem;
      span {
        display: inline-block;
        font-size: 1rem;
      }
    }
    span {
      display: block;
      color: #727272;
      margin: 0.5rem 0;
      font-size: 0.9rem;
    }
    .winner-text {
      text-align: center;
      font-size: 0.9rem;
      a {
        background-color: rgb(19, 175, 71);
        color: #fff;
        padding: 4px 8px;
        border-radius: 10px;
        font-weight: bolder;
        margin-top: 3px;
        display: inline-block;
      }
    }
  }
  &__timer {
    color: $red;
    font-weight: bolder;
  }
  &__national-lottery {
    a.national-lottery-link {
      color: #b9353c;
    }
  }
  .national-lottery-link {
    display: flex;
    align-items: center;
    font-weight: bolder;
    margin: 5px 0 10px 0;
    img {
      height: 30px;
      margin-right: 10px;
    }
  }
}
