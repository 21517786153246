.product {
  &__container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  &-carousel {
    height: 100%;
    width: 100%;
    max-height: 85vh;
    position: sticky;
    position: -webkit-sticky;
    top: 120px;
    left: 0 !important;
    right: unset;
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: $gray;
      position: relative;
      @include breakpoint($md) {
        background-color: #fff;
        min-height: 60vh;
      }
    }

    &__carousel {
      overflow: hidden;
      position: relative;
      height: 100%;
      width: 90%;
      margin: 0 auto;

      &__button {
        background-color: #fff;
        -webkit-box-shadow: 0 0 20px 0.5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 20px 0.5px rgba(0, 0, 0, 0.1);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;

        &--left {
          left: 2rem;

          img {
            transform: rotate(180deg);
          }
        }

        &--right {
          right: 2rem;
        }

        &__icon {
          display: block;
          font-size: 1.2rem;
        }
      }

      &__nav {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__pageButton {
        width: 100px;
        height: 5px;
        background-color: #aaa;
        -webkit-box-shadow: 0 0 20px 0.5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 20px 0.5px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        margin: 0 5px;

        &.current-page {
          background-color: #000;
        }
      }
    }

    &__slide {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // overflow: unset !important;
      img {
        height: 600px !important;
        max-width: 100%;
        @include breakpoint($md) {
          max-height: unset !important;
          height: unset !important;
          // max-width: 90%;
          // margin: 0 auto;
        }
      }
      &__magnify {
        // z-index: 99999999;
        left: 0 !important;
        img {
          // position: relative;
          // z-index: 999 !important;
          // height: 1000px !important;
          max-width: unset;
          max-height: unset;
          width: 700px !important;
          @include breakpoint($md) {
            height: 1000px !important;
            // width: 1000px !important;
          }
          @include breakpoint($lg2) {
            width: 820px !important;
            height: 1200px !important;
          }
        }
        // width: 800px !important;
      }

      // .textMagnify {
      //   cursor: pointer;
      //   & > div {
      //     &:last-child {
      //       // overflow: auto !important;
      //       z-index: 99999 !important;
      //     }
      //   }
      // }
      // &:hover img {
      //   cursor: zoom-in;
      // }

      &-container {
        position: relative;
        height: 100%;
        min-height: 20vh;
        @include baseTransition(0.3s);
      }

      &__image {
        height: 600px;
        object-fit: contain;
        transition: 0.5s all cubic-bezier(0.19, 1, 0.22, 1);
        @include breakpoint($md) {
          height: 500px;
          padding: 2rem 3rem;
        }
        img {
          max-height: 500px !important;
        }
      }
    }
  }
  &__bids {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 0.6rem;
    @include breakpoint($md) {
      margin-top: 1rem;
      padding-bottom: 0.8rem;
    }
    p {
      span {
        margin: 0;
        @include breakpoint($md) {
          font-size: 0.8rem;
        }
      }
    }
    &__container {
      max-height: 900px;
      overflow-y: scroll;
      padding-right: 0.2rem;
      h4 {
        margin-top: 1rem;
      }
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 11px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgb(0, 0, 0);
      }
      @include breakpoint($md) {
        max-height: 300px;
      }
    }
    &__input {
      p {
        font-weight: bolder;
        text-align: center;
        margin: 0.5rem 0;
        a {
          color: #0073c0;
        }
      }
      form {
        margin-top: 1rem;
        border-top: 1px dashed #cacaca;
        padding-top: 0.5rem;
        .error-feedback {
          color: $red;
          font-size: 0.9rem;
          font-weight: bolder;
          margin-top: 0.5rem;
        }
        .form-control-plaintext {
          font-family: $secondary-font;
          display: block;
          @include breakpoint($xl) {
            font-size: 1rem;
          }
          // @include breakpoint($xl2) {
          //   text-align: right;
          //   display: inline-block;
          // }
        }
        .form-control {
          background-color: #fff;
          border: 1px solid #ced4da;
          color: #495057;
          display: block;
          font-family: $secondary-font;
          font-size: 1rem;
          font-weight: 400;
          height: 43px;
          line-height: 1.5;
          padding: 0.5rem 0.75rem;
          // transition: border-color 0.15s ease-in-out,
          //   box-shadow 0.15s ease-in-out;
          text-align: center;
          width: 150px;
          margin: 0;
          @include breakpoint($md) {
            font-size: 0.9rem;
          }
          &.number {
            display: inline-block;
            width: 80px;
            text-align: center;
            margin-top: 0;
          }
        }
        label {
          font-family: $secondary-font;
          font-weight: bolder;
          font-size: 0.7rem;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #848484;
          letter-spacing: 0.5px;
        }
        .bid-container {
          margin-top: 5px;
          display: flex;
        }
        button {
          background-color: #000;
          color: #fff;
          padding: 0 0.8rem;
          height: 43px;
          width: 43px;
          font-size: 1.3rem;
          cursor: pointer;
          &:hover {
            // background-color: #495057;
          }
          &:disabled {
            background-color: #727272;
            cursor: not-allowed;
          }

          @include breakpoint($xl) {
            display: inline-block;
          }
          &.send {
            display: block;
            width: 100%;
            border: 0;
            background-color: #000;
            color: #fff;
            padding: 1rem 0;
            font-weight: bolder;
            font-family: $main-font;
            font-size: 1rem;
            height: auto;
            &:disabled {
              background-color: #828282 !important;
            }
          }
        }
      }
    }
  }
  &__content {
    position: relative;
    background-color: #fff;
    &-holder {
      padding: 2.5rem;
    }
  }

  &__header,
  &__name,
  &__description,
  &__price,
  &__details {
    margin-bottom: 2rem;
  }

  &__header {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-family: $main-font;
  }
  &__share {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include breakpoint($md) {
      justify-content: center;
    }
    span {
      color: #555;
      font-weight: bolder;
    }
  }
  &__brand {
    text-transform: uppercase;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 48%;
      transform: translateY(-48%);
      right: -1.2rem;
      height: 2.5px;
      width: 15px;
      background-color: #8a8a8a;
    }
  }
  &__genre {
    margin-left: 1.5rem;
    font-size: 0.9rem;
    color: $red;
    font-weight: bolder;
  }

  &__newModel {
    color: $red;
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;
    span {
      color: #727272;
      padding-right: 7px;
      // display: block;
    }
    &::before {
      content: "";
      display: block;
      width: 20px;
      height: 2px;
      margin: 0 10px;
      background-color: #555;
    }
  }

  &__name,
  &__price {
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 2.75rem;
  }

  &__name {
    text-transform: uppercase;
    width: 67.5%;
  }

  &__description {
    color: #555;
    font-size: clamp(1rem, 1.175vw, 1.4rem);
    line-height: clamp(1.4rem, 1.7vw, 2rem);
  }
  &__eta,
  &__stock {
    font-weight: bolder;
    margin-bottom: 1.5rem;
    span {
      color: #555;
      // font-weight: lighter;
    }
  }
  &__price {
    color: #333;
    span {
      color: #727272;
      font-size: 1rem;
    }
  }
  .quantity-selector {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    @include breakpoint($md) {
      justify-content: center;
    }
    button.counter-action {
      height: 45px;
      font-size: 1.7rem;
      width: 45px;
      background-color: rgb(213, 213, 213);
      @include breakpoint($md) {
        height: 55px;
        width: 55px;
      }
      &:hover {
        background-color: #dcdcdc;
        cursor: pointer;
      }
      &:disabled {
        background-color: rgba(239, 239, 239, 0.3);
        &:hover {
          cursor: not-allowed;
          background-color: rgba(239, 239, 239, 0.3);
        }
      }
    }
    h5 {
      font-size: 1.3rem;
    }
  }
  &__details {
    margin-top: 2rem;
    &__section {
      overflow: hidden;

      &__header {
        display: flex;
        align-items: center;
        padding: 1.25rem;
        background-color: $gray;
        cursor: pointer;
        @include baseTransition(0.3s);

        &__icon {
          margin-right: 20px;
        }

        &__name {
          font-size: clamp(1.2rem, 1.4vw, 1.65rem);
          font-weight: 600;
          flex-grow: 1;
        }

        &__drop-icon {
          margin-left: 10px;
          width: 30px;
          @include baseTransition(0.3s);
        }
      }

      &:hover {
        .product__details__section__header {
          background-color: #ccc;
        }
      }

      &:not(.open) {
        .product__details__section__header__drop-icon {
          transform: rotate(180deg);
        }

        .product__details__content {
          height: 0;
        }
      }
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 5px 0;
      @include baseTransition(0.3s);

      &__section {
        display: flex;
        padding: 1.5rem 1rem;
        border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        &__title {
          margin-right: 10px;
          min-width: 34%;
          font-size: clamp(1rem, 1vw, 1.2rem);
          font-weight: 600;
          font-family: $main-font;
        }

        &__text {
          flex-grow: 1;
          font-weight: 500;
          font-size: 1.1rem;
          line-height: 1.7rem;
          color: #555;

          & > span::after {
            content: ".\a";
            white-space: pre;
          }
        }
      }
    }
  }

  &__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1.5rem;
    width: 48%;
    cursor: pointer;
    @include baseTransition(0.3s);

    &-container {
      display: flex;
      justify-content: space-between;
      grid-gap: 1rem;
      flex-direction: column;
      p {
        font-size: 0.9rem;
        a {
          font-weight: bolder;
          color: #000;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__text {
      color: #fff;
      font-weight: 600;
      font-size: 1.2rem;
    }

    &--buy {
      background-color: $red;
      color: #fff;
      font-size: 1.2rem;
      font-family: $secondary-font;
      font-weight: bolder;
      &:hover {
        background-color: #b00309;
      }
      &:disabled {
        background-color: #a74e51;
        cursor: not-allowed;
      }
    }

    &--cart {
      background-color: #000;
      color: #fff;
      font-size: 1.2rem;
      font-family: $secondary-font;
      font-weight: bolder;
      &:hover {
        background-color: #333;
      }
      &:disabled {
        background-color: #7c7c7c;
        cursor: grab;
        pointer-events: none;
        // &:hover {
        // }
      }
    }
  }

  &__info {
    &__content {
      padding: 2.5rem;

      &-container {
        display: flex;
        align-items: center;
      }
    }

    &__title {
      text-transform: uppercase;
      font-size: clamp(1.4rem, 1.825vw, 2.2rem);
      line-height: clamp(2rem, 2.5vw, 3rem);
      font-weight: 500;
      width: 90%;
      margin-bottom: 2rem;

      &::before {
        content: "";
        display: block;
        margin-bottom: 2rem;
        width: 65px;
        height: 7.5px;
        background-color: $red;
      }
    }

    &__text {
      font-size: clamp(1rem, 1.3vw, 1.575rem);
      line-height: clamp(1.65rem, 1.825vw, 2.2rem);
    }

    &__image {
      &__container {
        display: flex;
        align-items: center;
        padding: 2rem !important;
      }

      &-container {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        grid-gap: 2rem;
        overflow-x: scroll;
        @include scrollbarReset(7.5px);
        padding: 2rem 0;
      }
    }
  }

  @include breakpoint($md) {
    &-carousel {
      min-height: 60vh;

      &__carousel {
        width: 100%;

        &__button {
          &--left {
            left: 5px;
          }

          &--right {
            right: 5px;
          }
        }
      }
      &__slide {
        max-width: 100vw;
        // overflow: hidden;
      }
    }

    &__content {
      &-holder {
        padding: 2.5rem 5px 1rem;
        text-align: center;
      }
    }

    &__header {
      margin-bottom: 1rem;
      justify-content: center;
    }

    &__name {
      width: 100%;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    &__details {
      text-align: left;

      &__section {
        &:not(.open) .product__details__content {
          display: none;
        }
      }
    }

    &__cta {
      width: 100%;
      &--buy {
        margin-top: 1rem;
      }
      &-container {
        flex-wrap: wrap;
      }
    }

    &__info {
      &__content {
        padding: 3rem 5px;

        &-container {
          order: 4;
        }
      }

      &__title {
        width: 100%;
        font-weight: 600;
      }

      &__image {
        width: 90%;

        &__container {
          order: 3;
          position: relative;
          padding: 1rem 0 2rem !important;

          &::after {
            content: "";
            display: block;
            position: absolute;
            // z-index: -1;
            background-color: #f3f3f3;
            width: 90%;
            height: 105%;
            top: 0;
            right: 0;
          }
        }

        &-container {
          grid-gap: 1rem;
          @include scrollbarReset(5px);
        }
      }
    }
  }
}
