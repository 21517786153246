@import "../abstracts/_variables.scss";
@import "../abstracts/_mixins.scss";

.product {
  position: relative;
  &-carousel {
    min-height: 50vh;
    // max-height: 90vh;
  }
  &__description {
    text-align: justify;
    @include breakpoint($xl) {
      margin: 1rem 0;
    }
  }
  &__winner {
    p {
      color: #2fb039;
      font-weight: bolder;
      margin-top: 10px;
      span {
        color: #000;
      }
    }
    a {
      background-color: rgb(19, 175, 71);
      color: #fff;
      padding: 4px 8px;
      border-radius: 10px;
      font-weight: bolder;
      margin-top: 3px;
      display: inline-block;
      text-align: center;
      @include breakpoint($xl) {
        text-align: unset;
      }
    }
  }
  &__bid {
    &__ticket-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      justify-content: space-between;
      z-index: 10;
      @include breakpoint($xl) {
        width: 53%;
      }
      &__header {
        background-color: #d2d2d2;
        padding: 1rem 10px;
        @include breakpoint($xl) {
          padding: 1rem 1.5rem;
        }
      }
      &__tickets {
        width: 25%;
        flex-wrap: wrap;
      }
      // align-items: center;
      // background-color: #777;
      // color: white;
      // cursor: pointer;
      // padding: 18px;
      // width: 100%;
      // border: none;
      // text-align: left;
      // outline: none;
      // font-size: 15px;
      .continue {
        &-active {
          background-color: #d2d2d2;
        }
      }
      .content {
        // display: none;
        overflow: hidden;
        background-color: #fbfbfb;
        // min-height: 300px;
        height: 0;
        transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
        &-heading {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          img {
            height: 15px;
            margin-right: 10px;
          }
        }
        &-media {
          margin-left: 10px;
          img {
            height: 25px !important;
            margin-right: 0.5rem;
          }
        }
        p {
          font-size: 0.9rem;
          text-align: justify;
        }
        .stripe-error-feedback {
          color: #ba004d;
          font-weight: bolder;
          text-align: center;
          margin-top: 10px;
        }
        #card-element {
          width: 100%;
          margin-top: 1rem;
          border: 1px solid #cfcfcf;
          border-radius: 10px;
          padding: 1rem;
          background-color: #fff;
        }
        &.active {
          padding: 1rem;
          height: auto;
        }

        button {
          background-color: #000;
          color: #fff;
          font-weight: bolder;
          padding: 0.8rem 3rem;
          border-radius: 5px;
          font-size: 1rem;
          font-family: $main-font;
          display: block;
          width: 100%;
          margin-top: 1rem;
          transition: box-shadow 0.3s ease-in-out;
          position: relative;
          z-index: 999;
          text-decoration: none;
          @include breakpoint($xl) {
            padding: 0.9rem 3rem;
          }
          &:hover {
            cursor: pointer;
            background-color: #000;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.511);
          }
        }
      }
      h6 {
        text-transform: uppercase;
        margin-right: 5px;
      }
      h4 {
        font-size: 1.1rem;
      }
      button {
        // color: $red;
        // background-color: transparent;
        font-weight: bolder;
        border-radius: 5px;
        font-size: 1rem;
        font-family: $main-font;
        text-decoration: underline;
        color: #fff;
        background-color: $red;
        padding: 0.7rem 1rem;
        text-decoration: none;
        @include breakpoint($xl) {
          padding: 0.7rem 2rem;
        }
        &:hover {
          cursor: pointer;
          background-color: lighten($red, 5%);
        }
      }
      // & > div {
      //   & > div {
      //     &:first-of-type {
      //       width: 25%;
      //       flex-wrap: wrap;
      //     }
      //   }
      // }
      // &.active,
      // &.collapsible:hover {
      //   background-color: #fff;
      // }
    }
  }
  &__ticket {
    &__carousel-container {
      .product-carousel {
        // position: unset;
      }
      .product__details {
        padding-left: 1rem;
      }
    }
    &__header {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      font-family: $main-font;
      text-transform: uppercase;
      letter-spacing: 2px;
      &__status {
        background-color: $red;
        border-radius: 10px;
        color: #fff;
        display: block;
        font-family: $main-font;
        font-size: 0.9rem;
        font-weight: bolder;
        margin: 10px auto 1rem auto;
        padding: 0.7rem 0.5rem;
        position: relative;
        text-align: center;
        transition: all 0.2s ease-in;
        width: 60%;
      }
    }
    &__prices {
      display: flex;
      span {
        font-size: 1.2rem;
        font-weight: bolder;
      }
    }
    &__content {
      &-holder {
        padding: 1.5rem 5px;
        // text-align: center;
        @include breakpoint($lg) {
          padding: 1.5rem;
        }
        @include breakpoint($xl) {
          padding: 1.5rem;
        }
        .product__name {
          // margin-bottom: 1rem;
          width: 100%;
          margin-bottom: 0;
        }
      }
    }
    &__container {
      &__tickets {
        margin-bottom: 1rem;
        position: relative;
        @include breakpoint($sm) {
          margin-left: -1rem;
          margin-right: -1rem;
        }
        &-overlay {
          &.show {
            width: 100%;
            height: 100%;
            opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #fff;
            z-index: 5;
            overflow-y: hidden;
            // @include breakpoint($md) {
            //   height: 100%;
            // }
          }
        }
        .seats {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 1rem;
          @include breakpoint($xl) {
            justify-content: flex-start;
          }
        }

        .seat {
          display: flex;

          padding: 5px;
          position: relative;
          @include breakpoint($md) {
            flex: 0 0 6%;
          }
          @include breakpoint($sm) {
            flex: 0 0 12%;
          }
          @include breakpoint($lg2) {
            flex: 0 0 9%;
          }
          @include breakpoint($xl) {
            flex: 0 0 7.28571428571429%;
          }
          // &:nth-child(3) {
          //   margin-right: 14.28571428571429%;
          // }
          input[type="checkbox"] {
            position: absolute;
            opacity: 0;
          }
          input[type="checkbox"]:checked {
            + label {
              background: #0075ff;
              border: 1px solid #004fc5;
              -webkit-animation-name: rubberBand;
              animation-name: rubberBand;
              animation-duration: 300ms;
              animation-fill-mode: both;
              color: #fff;
            }
          }
          input[type="checkbox"]:disabled {
            + label {
              background: #e6e6e6;
              text-indent: -9999px;
              overflow: hidden;
              border: 1px solid #cfcfcf;
              &:after {
                content: "X";
                text-indent: 0;
                position: absolute;
                top: 4px;
                left: 50%;
                transform: translate(-50%, 0%);
              }
              &:hover {
                box-shadow: none;
                cursor: not-allowed;
              }
            }
          }
          label {
            display: block;
            position: relative;
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.5rem;
            padding: 4px 0;
            background: #a0ccfc;
            border: 1px solid #67a5fa;
            border-radius: 5px;
            animation-duration: 300ms;
            animation-fill-mode: both;

            &:before {
              content: "";
              position: absolute;
              width: 75%;
              height: 75%;
              top: 1px;
              left: 50%;
              transform: translate(-50%, 0%);
              background: rgba(255, 255, 255, 0.4);
              border-radius: 3px;
            }
            &:hover {
              cursor: pointer;
              box-shadow: 0 0 0px 2px #5c6aff;
            }
          }
        }
      }
    }
  }
  &__national-lottery {
    display: flex;
    align-items: center;
    margin-top: 16px;
    a,
    p {
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
        max-height: 30px;
      }
    }
    p {
      font-weight: 600;
    }
    a {
      color: #b9353c;
      font-weight: bolder;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

.overlay {
  &.show {
    width: 100%;
    height: calc(110vh * 3.2);
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 30;
    overflow-y: hidden;
    // @include breakpoint($md) {
    //   height: 100%;
    // }
  }
}
