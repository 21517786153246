.home {
  .carousel-root {
    .carousel {
      // height: 610px;
      .slide {
        height: 610px;
        @include breakpoint($xl) {
          height: 500px;
        }
        @include breakpoint($xl2) {
          height: 610px;
        }
        @include breakpoint($md) {
          height: 260px;
        }
        &__media {
          // height: 610px;
        }
        img {
          // position: absolute;
          // text-align: ce;
          // height: 610px;
          vertical-align: middle;
          position: absolute;
          top: 75%;
          left: 0;
          transform: translateY(-75%);
        }
        .legend {
          height: 90%;
          background-color: transparent;
          opacity: 1;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          flex-direction: column;
          // position: relative;
          h2 {
            font-size: 2.5rem;
            width: 40%;
            text-align: left;
            margin-bottom: 1rem;
            @include breakpoint($md) {
              margin-bottom: 0.2rem;
              font-size: 1.5rem;
              width: 70%;
            }
          }
        }
      }
      .thumb {
        img {
          height: 37px;
        }
      }
    }
  }

  .jumbotron {
    height: 80vh;

    &__container {
      height: 100%;
    }

    &__carousel {
      height: 100%;
      position: relative;
      overflow: hidden;

      &__nav {
        margin-top: -30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__pageButton {
        cursor: pointer;
        border-radius: 50%;
        margin: 0 5px;
        width: 12.5px;
        height: 12.5px;
        background-color: rgba(255, 255, 255, 0.75);
        z-index: 2;
        @include baseTransition(0.3s);

        &:hover,
        &.current-page {
          background-color: #000;
        }
      }
    }

    &__slide {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      padding: 5vh 70px;
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: flex-end;
      @include baseTransition(0.3s);

      &:nth-child(1) {
        background-image: url("../../images/jumbotron-bg.jpg");
      }
      &:nth-child(2) {
        background-image: url("../../images/watch-men--index.jpg");
      }
      &:nth-child(3) {
        background-image: url("../../images/watch-women--index.jpg");
      }

      &:not(.current-slide) {
        opacity: 0;
        visibility: hidden;
      }

      &-container {
        padding: 0;
        margin: 0;
        position: relative;
        height: 100%;
        @include baseTransition(0.3s);
      }

      &__title {
        color: #fff;
        font-size: clamp(1.45rem, 2.9vw, 2.65rem);
        font-weight: 500;
        margin-bottom: 1rem;
      }

      &__content {
        width: 40%;
      }
    }

    @include breakpoint($md) {
      height: 70vh;

      &__slide {
        padding: 2.5vh 15px;

        &:nth-child(1) {
          background-image: url("../../images/jumbotron-mobile-bg.jpg");
        }
        &:nth-child(2) {
          background-image: url("../../images/watch-men--index.jpg");
        }
        &:nth-child(3) {
          background-image: url("../../images/watch-women--index.jpg");
        }

        &__content,
        &__cta-container {
          text-align: center;
        }

        &__content {
          width: 100%;
          margin-bottom: 15px;
        }

        &__title {
          font-weight: 700;
        }
      }
    }
  }

  .brands__container {
    padding: 2rem 0;
    .col-lg-3 {
      height: 200px;
      @include breakpoint($md) {
        height: auto;
      }
    }
    @include breakpoint($md) {
      padding: 0;
      padding-bottom: 1.5rem;
    }
    a {
      border: 1px solid #c0c0c0;
      box-shadow: 0 4px 10px 1px rgba(216, 216, 216, 0.29);
      height: 160px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      @include breakpoint($md) {
        height: 110px;
      }
      &:hover {
        border: 2px solid #9a9a9a;
        transform: translateY(-10px);
      }
    }
    img {
      width: 100%;
      height: auto;
      // width: auto;
      // max-width: 100%;
      // height: 100%;
      padding: 0 2rem;

      transition: all 0.3s ease-in-out;

      @include breakpoint($md) {
        padding: 1.5rem;
      }
      @include breakpoint($xl2) {
        padding: 3rem 2rem;
      }
    }
    .container {
      .row {
        div {
          margin-top: 1rem;
          @include breakpoint($xl2) {
            &:first-of-type {
              margin-top: 0;
            }
            &:first-child {
              margin-top: 0;
            }
            &:nth-child(1) {
              margin-top: 0;
            }
            &:nth-child(2) {
              margin-top: 0;
            }
            &:nth-child(3) {
              margin-top: 0;
            }
            &:nth-child(4) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  .about {
    &__container {
      padding: 10vh 10vw 0;
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 250px;
        width: 460px;
        background-color: $gray;
        z-index: -1;
        left: 0;
        top: 42.5%;
      }
    }

    &__title {
      text-transform: uppercase;
      font-size: clamp(1rem, 1.925vw, 1.75rem);
      line-height: clamp(1.5rem, 2.75vw, 2.5rem);
      position: relative;
      margin-bottom: 1rem;

      &::before {
        content: "";
        position: absolute;
        top: -25px;
        display: block;
        width: 50px;
        height: 10px;
        background-color: $red;
      }
    }

    &__text {
      line-height: 1.5rem;
    }

    @include breakpoint($md) {
      &__container {
        padding: 5vh 15px 0;

        &::after {
          width: 50%;
          top: 40%;
        }
      }

      &__title {
        font-weight: 500;

        &::before {
          height: 5px;
          width: 35px;
          top: -20px;
        }
      }

      &__text {
        font-size: 0.95rem;
      }
    }
  }

  .product-gallery {
    &__container {
      padding: 5vh 70px;
    }

    &__header {
      margin-bottom: 2rem;
      display: flex;
      align-items: center;

      &__title,
      &__link {
        font-weight: 600;
        font-size: 0.9rem;
      }

      &__title {
        display: flex;
        align-items: center;
        @include breakpoint($xl) {
          font-size: 1.5rem;
        }
        &::after {
          content: "";
          display: block;
          margin: 0 10px;
          height: 2.5px;
          width: 15px;
          background-color: #8a8a8a;
        }
      }

      &__link {
        color: $red;
        padding: 2.5px 0;
        border-bottom: 2.5px solid transparent;
        @include breakpoint($xl) {
          font-size: 1.2rem;
        }
        &:hover {
          border-color: $red;
        }
      }
    }

    &__product {
      padding: 0 10px;
      // min-width: 22vw;
      // max-width: 23vw;
      // @include breakpoint($md) {
      //   max-width: 290px;
      // }
      &__holder {
        height: auto;
        min-width: 21vw;
      }
      &-container {
        padding-bottom: 2rem;
        margin-left: -10px;
        margin-right: -10px;
        // display: flex;
        // flex-wrap: nowrap;
        display: grid;
        overflow-x: scroll;
        @include scrollbarReset(5px);
        @include breakpoint($lg) {
          grid-template-columns: 1fr;
        }
        // @include breakpoint($lg) {
        //   grid-template-columns: repeat(3, 1fr);
        //   }
        @include breakpoint($lg2) {
          grid-template-columns: repeat(4, 1fr);
        }
        @include breakpoint($xl2) {
          grid-template-columns: repeat(4, 1fr);
        }
      }

      &__image {
        height: 390px;
        width: 95%;
        @include breakpoint($md) {
          height: auto;
        }
        &-container {
          overflow: hidden;
        }
      }

      &__content {
        text-align: center;
        padding: 1rem 3rem;
        background-color: #fff;
        @include breakpoint($md) {
          padding: 1rem 2rem;
        }
      }

      &__name {
        text-transform: uppercase;
        font-size: 0.85rem;
        font-family: $main-font;
        font-weight: 600;
        color: #000;
      }
      &__price {
        color: #000;
        font-family: $secondary-font;
        margin-top: 0.5rem;
        span {
          color: $red;
        }
      }
    }

    @include breakpoint($md) {
      &__container {
        padding: 2.5vh 15px 5vh;
      }

      &__header {
        margin-bottom: 1rem;
      }

      &__product {
        min-width: 280px;
        @include breakpoint($md) {
          min-width: 250px;
        }
        &__holder {
          height: 420px;
          width: auto;
          min-width: auto;
          @include breakpoint($md) {
            height: auto;
          }
        }
        &-container {
          padding-bottom: 1rem;
          @include scrollbarReset(2.5px);
          height: auto;
          margin-top: 2rem;
        }
        &__image {
          &-container {
            width: 100%;
            height: 390px;
            overflow: hidden;
            @include breakpoint($md) {
              height: auto;
            }
          }
        }
      }
    }
    @include breakpoint($xl-only) {
      &__product {
        &__image {
          height: 400px;
          width: 95%;
          &-container {
            overflow: hidden;
          }
        }
      }
    }
    @include breakpoint($xxl) {
      &__product {
        &__image {
          height: 600px;
          width: 95%;
          &-container {
            overflow: hidden;
          }
        }
      }
    }
  }

  .catalogue-cta {
    &__container {
      // display: flex;
      // flex-wrap: wrap;
      // align-items: center;
      // justify-content: center;
      overflow: hidden;
      height: 55vh;
      padding-bottom: 10px;
    }

    &__cta {
      padding: 0 5px;
      // width: 50%;
      height: 100%;

      &__content {
        height: 100%;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        text-align: center;
        padding: 4rem 2.5rem;
        @include baseTransition(0.3s);
      }

      &:hover {
        .catalogue-cta__cta__content {
          background-size: 105%;
        }
      }

      &__title {
        text-transform: uppercase;
        font-size: clamp(1.25rem, 2.2vw, 2rem);
        color: #fff;
      }

      &--men {
        padding-left: 0;

        .catalogue-cta__cta__content {
          background-image: url("../../images/watch-men--index.jpg");
        }
      }
      &--women {
        padding-right: 0;

        .catalogue-cta__cta__content {
          background-image: url("../../images/watch-women--index.jpg");
        }
      }
    }

    @include breakpoint($md) {
      &__container {
        height: auto;
        padding-bottom: 15px;
      }

      &__cta {
        padding-right: 15px;
        padding-left: 15px;
        height: 33vh;

        &__content {
          padding: 1rem;
        }

        &__title {
          font-weight: 500;
        }

        &--men {
          margin-bottom: 15px;
        }
      }
    }
  }

  .social-cta {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    &__content {
      height: 35vh;
      width: 100%;
      background: linear-gradient(to bottom, #3a5795 0%, #2b4886 100%);
      @include baseTransition(0.4s);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 1.65rem;
      &--ig {
        background: radial-gradient(
          circle at 20% 120%,
          #fdf497 0%,
          #fdf497 5%,
          #fd5949 45%,
          #d6249f 60%,
          #285aeb 90%
        );
      }
      &--tk {
        background: linear-gradient(to bottom, #28ffff 0%, #fe2d52 100%);
      }
      &:hover {
        background-color: #072238;

        .social-cta__text__highlight {
          border-color: #fff;
        }
      }
      img {
        height: 30px;
      }
    }
    &__container {
      a {
        padding: 0 1rem;
      }
    }
    &__icon {
      margin-right: 20px;
    }

    &__text {
      &__highlight {
        border-bottom: 2px solid transparent;
        @include baseTransition(0.5s);
      }
    }

    @include breakpoint($md) {
      grid-template-columns: auto;
      &__content {
        padding: 1rem 2rem;
        height: 17.5vh;
      }

      &__text {
        font-size: 1.2rem;

        &__highlight {
          font-weight: 600;
        }
      }
    }
  }

  .about-cta {
    &__container {
      padding: 5vh 70px 2.5vh;
    }

    &__category {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 2.5vh 0;

      &__title {
        font-size: clamp(1rem, 1.65vw, 1.5rem);
        line-height: clamp(1.75rem, 2.8vw, 2.5rem);
        text-transform: uppercase;
        width: 60%;
        position: relative;
        margin-bottom: 1rem;

        &::before {
          content: "";
          display: block;
          position: absolute;
          margin-top: -35px;
          width: 45px;
          height: 10px;
          background-color: $red;
        }
      }

      &__description {
        font-weight: 500;
        font-size: clamp(0.915rem, 1.21vw, 1.1rem);
        line-height: clamp(1.35rem, 1.85vw, 1.65rem);
        margin-bottom: 1.5rem;
      }
      &__content {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      &__cta {
        &-container {
          &--mobile {
            display: none;
            padding: 0;

            .about-cta__category__cta {
              padding: 0.5rem 0;
            }
          }
        }
      }

      &__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;

        &-container {
          position: relative;
        }

        &:hover {
          & + .about-cta__category__image__cta i {
            transform: translateX(10px);
          }
        }

        &__cta {
          position: absolute;
          z-index: 1;
          bottom: 2.5rem;
          right: 4rem;
          display: flex;
          align-items: center;
          color: #fff;
          font-weight: 600;
          cursor: pointer;

          &__text {
            margin-right: 10px;
          }

          &__icon {
            font-size: 1.5rem;
            @include baseTransition(0.3s);
          }
        }
      }
    }
    @include breakpoint($md) {
      &__container {
        padding: 0;
      }

      &__category {
        &__image {
          &-container {
            padding-right: 0;
          }
          &__cta {
            bottom: 1.5rem;
            right: calc(1.5rem + 15px);
          }
        }

        &__title {
          position: unset;
          width: 100%;
          font-family: $secondary-font;

          &::before {
            position: unset;
            margin-top: 2rem;
            margin-bottom: 1rem;
            height: 5px;
            @include breakpoint($md) {
              margin-top: 0;
            }
          }
        }

        &__cta {
          &-container {
            &--mobile {
              display: block;
            }

            &:not(.about-cta__category__cta-container--mobile) {
              display: none;
            }
          }
        }
        &__content {
          padding: 0 1rem;
        }
        &:last-child {
          & > div:first-child {
            order: 2;
          }

          & > div:last-child {
            order: 1;
          }
        }
      }
    }
  }

  .categories {
    &__container {
      padding: 5vh 70px;
      background-color: $gray;
    }

    &__header {
      display: none;
      padding: 1.5rem 15px;
    }

    &__title {
      text-transform: uppercase;
      font-family: $secondary-font;
      font-size: 1rem;
    }

    &__category {
      &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }

      &__image {
        width: 100%;
        display: block;
        margin-bottom: 0;
        &-container {
          @include breakpoint($lg2) {
            height: 283px;
            width: 100%;
            overflow: hidden;
            img {
              height: 100%;
            }
          }
        }
      }

      &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 1.75rem;
        background-color: #fff;

        &:hover i {
          transform: translateX(10px);
        }
      }

      &__name,
      &__icon {
        font-weight: 600;
        color: #000;
      }

      &__name {
        text-transform: uppercase;
        font-family: $main-font;
        font-size: 1.1rem;
      }

      &__icon {
        font-size: 1.25rem;
        @include baseTransition(0.3s);
      }
    }

    @include breakpoint($md) {
      &__container {
        padding: 2.5vh 0;
      }

      &__header {
        display: block;
      }

      &__category {
        &-container {
          flex-wrap: nowrap;
          overflow-x: scroll;
        }
      }
    }
  }
}
