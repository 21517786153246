@import "../abstracts/mixins";
@import "../abstracts/variables";

.sign-up {
  @include breakpoint($sm-only) {
    min-height: 75vh;
  }
  .container-fluid {
    height: 100%;
    @include breakpoint($xl) {
      padding: 0;
    }
    .row {
      height: 100%;
      .col-12 {
        background-color: #ededed;
        padding: 0;
        &:nth-child(2n) {
          padding: 3rem 1.5rem;
          @include breakpoint($md) {
            padding: 3rem 1rem;
          }
        }
      }
    }
  }
  &__content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 3rem 2rem;
    .alert {
      position: fixed;
      top: 120px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      padding: 1rem;
      margin-bottom: 1rem;
      border: 1px solid transparent;
      border-radius: 0.25rem;
    }
    .alert-success {
      color: #0f5132;
      background-color: #d1e7dd;
      border-color: #badbcc;
    }
    p {
      margin-top: 0.8rem;
    }
    a.login {
      color: #000;
      font-weight: bolder;
      text-align: center;
      font-size: 0.9rem;
      padding: 0;
      &:hover {
        text-decoration: underline;
        color: #000;
      }
    }
    form {
      margin: 1rem 0;
      .form-group {
        display: flex;
        flex-direction: column;
        .form-control {
          display: block;
          width: 100%;
          height: 50px;
          padding: 0.375rem 0.75rem;
          font-family: $secondary-font;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #495057;
          background-color: #fff;
          background-clip: padding-box;
          // border: 1px solid #ced4da;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        }
        .error-feedback {
          color: $red;
          font-size: 0.9rem;
          font-weight: bolder;
          margin-top: 0.5rem;
        }
        label {
          font-family: $secondary-font;
          font-weight: bolder;
          font-size: 0.8rem;
          letter-spacing: 1px;
          margin-bottom: 0.5rem;
          text-transform: uppercase;
        }
      }
      button.auth__cta {
        background-color: #000;
        width: 100%;
        color: #fff;
        font-family: $main-font;
        font-size: 1.1rem;
        font-weight: bolder;
        margin-top: 1rem;
        padding: 1rem 0;
        &:hover {
          background-color: lighten(#000, 15%);
          cursor: pointer;
        }
        &:disabled {
          background-color: #495057 !important;
          cursor: not-allowed;
        }
      }
    }
    .error-container {
      background-color: rgb(241, 241, 241);
      border-left: 4px solid $red;
      padding: 1rem 0;
      margin: 1rem 0;
      p {
        padding-left: 1.5rem;
        span {
          font-weight: bolder;
        }
      }
    }
  }
  &__media {
    background-image: url(../../images/register-jumbotron.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    @include breakpoint($md) {
      height: 450px;
    }
  }
}

p.errors {
  color: $red;
  text-align: center;
  font-weight: bolder;
}
