.confirm-payment {
  position: relative;
  overflow: hidden;
  z-index: 99999999999999;
  height: 64px;
  span {
    position: relative;
    color: #fff;
    font-size: 1rem;
    display: inline-flex;
    margin-left: 0.8rem;
    font-size: 1.1rem;
  }
  &.loading {
    cursor: wait;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      border-radius: 3px;
      width: 150%;
      height: 100%;
      background: repeating-linear-gradient(
        60deg,
        #e0e0e0,
        #e0e0e0,
        0.75rem,
        #cbcbca 0.75rem,
        #cbcbca 1.5rem
      );
      animation: load 1s infinite linear;
    }
    span {
      color: #000;
    }
  }
  @keyframes load {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-1.75rem);
    }
  }
}
