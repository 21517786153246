@import "../abstracts/_variables.scss";
@import "../abstracts/_mixins.scss";

.products {
  &__genre__header {
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      color: #fff;
      font-size: 2rem;
    }
  }
}
