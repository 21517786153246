.cart__products-warning {
  background-color: #ffe19a;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  p {
    font-size: 1.1rem;
    font-weight: bolder;
  }
  ul {
    padding-left: 1rem;
  }
}
