@import "./assets/scss/abstracts/variables";
@import "./assets/scss/abstracts/mixins";
@import "./assets/scss/abstracts/misc";
@import "./assets/scss/components/navbar";
@import "./assets/scss/components/cta-button";
@import "./assets/scss/components/whatsapp-cta";
@import "./assets/scss/components/footer";
@import "./assets/scss/pages/home";
@import "./assets/scss/pages/catalogue";
@import "./assets/scss/pages/cart";
@import "./assets/scss/pages/product";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $main-font;
  font-weight: 600;
}

body {
  margin: 0;
  font-family: $secondary-font !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include breakpoint($md) {
    padding-bottom: 0;
    overflow-x: hidden;
  }
}

.jumbotron {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include breakpoint($md) {
    padding-top: 0;
    background-color: #fff;
    height: 250px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  @include baseTransition(0.25s);
  text-decoration: none;
}

input,
button {
  border: none;
  outline: none;
  @include baseTransition(0.2s);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::selection {
  background-color: $red;
  color: #fff;
}

.hidden {
  display: none;
}

.v-hidden {
  visibility: hidden;
}

#main-container {
  margin-top: -1.5px;
}

.h-50vh {
  height: 50vh;
}
