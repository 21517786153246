@import "../abstracts/mixins";
@import "../abstracts/variables";

.reviews {
  &__wrapper {
    background-color: #f8f8f8;
    // .container {
    // }
  }
  &__header {
    background-color: #000;
    padding: 2.5rem 0;
    h3 {
      color: #fff;
      font-size: 1.8rem;
    }
  }
  &__container {
    padding-top: 2rem;
    padding-bottom: 3rem;
    max-height: 80vh;
    overflow-y: auto;
    .container {
      & > div {
        display: grid;

        gap: 1rem;
        margin-top: 1rem;
        @include breakpoint($xl) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
  &__item {
    background-color: #fff;
    padding: 1.7rem 1.2rem;
    border-bottom: 2px solid $red;
    // &:not(:first-child) {
    //   margin-top: 1rem;
    // }
    img {
      margin-right: 3px;
      height: 17px;
    }
    h4 {
      color: #0b3461;
    }
    h5 {
      font-size: 1rem;
    }
    h6 {
      font-size: 0.85rem;
    }
    p {
      margin: 0.4rem 0;
    }
  }
  &__input {
    background-color: #fff;
    padding: 2rem 0;
    @include breakpoint($md) {
    }
    a {
      background-color: #000;
      color: #fff;
      padding: 1rem 2rem;
      font-weight: bolder;
      display: block;
      width: fit-content;
      margin: 1rem auto 0 auto;
      &:hover {
        background-color: #565656;
      }
    }
    &__container {
      width: 70%;
      @include breakpoint($md) {
        width: 100%;
      }
      p {
        font-weight: bolder;
      }
      form {
        margin-top: 1rem;
        .error-feedback {
          color: $red;
          font-size: 0.9rem;
          font-weight: bolder;
          margin-top: 0.5rem;
        }
        .form-control {
          background-clip: padding-box;
          background-color: #fff;
          border: 1px solid #ced4da;
          color: #495057;
          display: block;
          font-family: $secondary-font;
          font-size: 1rem;
          font-weight: 400;
          height: 50px;
          line-height: 1.5;
          margin-top: 0.7rem;
          padding: 0.5rem 0.75rem;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          width: 100%;
          &:disabled {
            background-color: #ebebeb;
          }
        }
        label {
          font-family: $secondary-font;
          font-weight: bolder;
          font-size: 0.8rem;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #848484;
          letter-spacing: 0.5px;
        }
        textarea {
          resize: none;
        }
      }
      button {
        display: block;
        width: 100%;
        border: 0;
        background-color: #000;
        color: #fff;
        padding: 1.5rem 0;
        font-weight: bolder;
        font-family: $main-font;
        font-size: 1.1rem;
        cursor: pointer;
      }
    }
  }
}
